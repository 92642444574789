<script setup>
import { onMounted, ref } from "vue";
import roomService from "@/services/roomService";
import * as roomStatus from "@/constants/roomStatus";
import RoomItem from "./RoomItem.vue";
import RoomItemAnonymous from "./RoomItemForAnonymous .vue";
import useAuthStore from "@/store/authStore";
const authStore = useAuthStore();
const rooms = ref();

const filter = {
  status: null,
  cityId: null,
  ageUser: null,
};
const isAnonymous = ref();
onMounted(async () => {
  debugger
  isAnonymous.value = !authStore.user;
  if(isAnonymous.value){
      rooms.value = await roomService.getRoomsAnonymous();
    }
  filter.status = roomStatus.Awaiting;
  if (authStore.user.roles.includes(2)) {
    filter.cityId = authStore.user.city.id;
  } else {
    filter.cityId = authStore.user.city.id;
    filter.ageUser = authStore.user.age;
  }
  rooms.value = await roomService.getRooms(filter);
});
</script>

<template>
  <div class="rooms-container">
    <div v-for="room in rooms" v-bind:key="room.id">
      <div v-if="isAnonymous" >
        <RoomItemAnonymous class="roomItem" :value="room" />
      </div>
      <div v-else>
        <router-link :to="`/room/${room.id}`">
          <RoomItem class="roomItem" :value="room" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.rooms-container {
  margin: 0.5rem 0;
  &__item {
    margin: 1rem 0;
  }
}
</style>
