<script setup lang="ts"> 
import File from "@/models/File";
import useImageLightboxStore from "@/store/imageLightboxStore";
import fileService from "@/services/fileService";
import { useAuthStore } from "@/store";
import { onMounted } from "vue";
import useFileStore from '@/store/fileStore';
import { useToast } from 'vuestic-ui';

const authStore = useAuthStore();
const fileStore = useFileStore();
const fileBaseURL = `${import.meta.env.VITE_BACKEND_URL}/`; 
const { init } = useToast();
const imageLightboxStore = useImageLightboxStore();

const props = defineProps<{
  userId?: number | undefined;
}>();

const assignAvatar = async(fileId: number) => {
  await fileStore.assignAvatar(fileId, authStore.user.id);
};

const deleteFileById = async (id: number) => {
  try {
    await fileStore.deleteFileById(id);
    init({ message: `Файл успешно удален` });
  } catch { 
    init({ message: `Произошла ошибка при удалении файлов` });
  }
};

onMounted(async() => {
  if (props.userId) {
    await fileStore.getUserPhotos(props.userId);
  }
});
</script>

<template>
  <div class="gallery">
    <div class="card" v-for="(file, i) in fileStore.items" :key="file.id">
      <div class="image-container">
        <va-image
          fit="contain"
          @click="() => showImage(i)"
          :src="fileBaseURL + file.path"
          :fallbackText="file.name"
          class="gallery-image"
        />
        <div class="buttons-overlay">
          <va-button
            v-if="file.type !== 0 && file.id"
            color="primary"
            size="small"
            @click="() => assignAvatar(file.id)"
            class="avatar-button"
          >
            Сделать аватаром
          </va-button>
          <va-button
            v-if="file.id"
            color="danger"
            size="small"
            @click="() => deleteFileById(file.id)"
            class="delete-button"
          >Удалить</va-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.card {
  position: relative;
  width: 200px;
  height: 200px;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.buttons-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.3); /* Полупрозрачный фон */
  transition: background 0.3s ease;
  opacity: 0;
}

.image-container:hover .buttons-overlay {
  opacity: 1;
}

.avatar-button,
.delete-button {
  display: flex;
  align-items: center;
  color: white;
  font-size: 0.75rem;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  opacity: 0.8;
}

.avatar-button {
  background: rgba(0, 123, 255, 0.7); /* Полупрозрачный синий фон */
}

.delete-button {
  background: rgba(220, 53, 69, 0.7); /* Полупрозрачный красный фон */
}
</style>
