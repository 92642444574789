<script setup lang="ts">
import { reactive, ref, onMounted, watchEffect} from "vue";
import { useForm } from 'vuestic-ui/web-components';
import { useToast } from 'vuestic-ui';
import { useRouter, useRoute } from "vue-router";
import authService from "@/services/authService";
import { Role } from "@/enums/roles";
import { Gender, genders } from "@/enums/genders";
import Account from "@/models/Account";
import cityService from "@/services/cityService";
import validateEmail from '@/helpers/validators/validateEmail';
import validatePhone from '@/helpers/validators/validatePhone';
import FileGallery from '@/components/files/FileGallery.vue';
import FileUpload from '@/components/files/FileUpload.vue';
import useFileStore from '@/store/fileStore';
import fileService from "@/services/fileService";
import useAuthStore from '@/store/authStore';


const userStore = useAuthStore();
const { isValid, validate } = useForm('formRef');
const { init } = useToast();
const router = useRouter();


const emit = defineEmits(['cancel']);
const fileStore = useFileStore();
// const props = defineProps<{
// 		editMode?: boolean;
// 	}>();
const cities=ref([]) as any;
const galleryPhoto = ref([]) as any;

const formData = reactive({
  roles: [Role.User],
  age: 18,

} as Account);
const user=ref();

const submitForm = async (formEl) => {
  if (!formEl) return;
	if (!editMode.value) {
		user.value = await authService.register(formEl);
		galleryPhoto.value = await fileService.uploadPhotos(galleryPhoto.value, user.value.user.id);
		userStore.user = user.value.user;		
		router.push({ name: "FirstPage" });
	}
	else {
		user.value = await authService.editProfile(formData);
		userStore.user = user.value.user;	
		router.push({ name: "FirstPage" });
	}
  
};
async function loadFiles(event: Iterable<unknown> | ArrayLike<unknown>) {
	Array.from(event).forEach(async (file) => {
	galleryPhoto.value;

	});

}
const cancel = () => {
	if(editMode.value)
	router.push({ name: "Profile" });
	else
	router.push({ name: "FirstPage" });
}
const editMode = ref(false);
onMounted(async () => {
		cities.value = await cityService.getCities();
		if(userStore.user)
			editMode.value = true;
		if (editMode.value) {
			debugger
			Object.assign(formData, userStore.user);
			 galleryPhoto.value = await fileService.getUserPhotos(userStore.user.id);
		}
});
</script>

<template>
<VaCard>
    <va-card-title>
		<span class="va-h4">
			{{ editMode ? "Редактирование профиля": "Регистрация"}}
		</span>
	</va-card-title>

  <va-card-content >
    <va-form ref="formRef">
      <div class="row">
				<div class="flex xs12">
					<va-input
						v-model="formData.userName"
						label="Имя"
						:rules="[(value) => (value && value.length > 0) || 'Необходимо ввести имя']" />
				</div>
				<div class="flex xs12">
					<va-input
						v-model="formData.password"
						label="Пароль"
						type="password"
						:rules="[(value) => (value && value.length > 3) || 'Необходимо пароль более 3 символов']" />
				</div>
				<div class="flex xs12">
					<va-input
						v-model="formData.confirmPassword"
						label="Подтвердите пароль"
	
						type="password" />
				</div>
				<div class="flex xs12">
					<va-select
						v-model="formData.gender"
						label="Ваш пол"
						:options="genders"
						value-by="id"
						:rules="[(value) => value || 'Необходимо указать пол']" />
				</div>
        		<div class="flex xs6">
					<va-counter
						label="Возраст"
						v-model="formData.age"
						:min="18"
           				:max="100"
						manual-input 
           				:rules="[(value) => value || 'Необходимо указать ваш возраст']"/>
				</div>
				<div class="flex xs12">
					<va-select
						v-model="formData.cityId"
						label="Ваш город"
						:options="cities"
						text-by="name"
						value-by="id"
						searchable
            			:rules="[(value) => value || 'Необходимо указать ваш город']" />
				</div>
        		<div class="flex xs12">
					<va-input
						v-model="formData.email"
						label="Email"
						:rules="[(value) => (value && validateEmail(value)) || 'Необходимо ввести почту']" />
				</div>
       			 <div class="flex xs12">
					<va-input
						v-model="formData.phoneNumber"
						label="Номер телефона"
						:rules="[(value) => (value && validatePhone(value)) || 'Необходимо ввести номер телефона']" />
				</div>
        </div>
		<div>
				<FileUpload
				  v-model="galleryPhoto"
				  typeFile="galleryPhoto"
				  uploadButtonText="Загрузить фото"
				  undoButtonText="Удалить"
				  @fileAdded="loadFiles($event)"
				/>
		</div>
		<div style="margin: 1%;" >
			<file-gallery :userId="userStore?.user?.id " />
		</div>
    </va-form>

	</va-card-content>
<div ></div>
	<va-card-actions class="butBlock">
		<va-button
			@click="submitForm(formData)"
			:disabled="!isValid">
			{{ editMode ? "Сохранить" : "Создать" }}
		</va-button>
		<va-button
			@click="cancel()"
			preset="outline">
			Закрыть
		</va-button>
	</va-card-actions>
</VaCard>
</template>

<style scoped lang="less">


/* Центрирование всей формы */
.va-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Ширина формы и отступы для адаптивности */
.row {
  width: 100%;
  max-width: 600px; /* Максимальная ширина формы для больших экранов */
  padding: 1em;
  display: flex;
  flex-direction: column;
}

/* Элементы формы */
.flex {
  width: 100%;
  margin-bottom: 1em;
}

/* Центрирование фотографии и подписи */
.avatar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
}

/* Настройки фото */
.avatar {
  width: 100%;
  max-width: 150px; /* Базовый размер фото */
  height: auto;
  border-radius: 50%;
  object-fit: cover;
}

/* Подпись для фото */
.caption {
  margin-top: 0.5em;
  font-size: 14px;
  color: #555;
}

/* Кнопки действия */
.butBlock {
  display: flex;
  justify-content: center;
  gap: 1em;
  margin-top: 1em;
}

/* Медиазапросы для разных экранов */
@media (max-width: 1200px) {
  /* Параметры для планшетов */
  .row {
    max-width: 500px;
  }
}

@media (max-width: 768px) {
  /* Параметры для смартфонов */
  .row {
    max-width: 100%; /* Растягиваем форму на весь экран */
    padding: 0.5em;
  }

  .avatar {
    max-width: 100px; /* Уменьшаем фото на маленьких экранах */
  }

  .caption {
    font-size: 12px; /* Уменьшаем шрифт подписи на маленьких экранах */
  }
}
</style>

