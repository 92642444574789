<script setup>
import {  ref,  } from "vue";
import moment from "moment";

const props = defineProps({
  value: Object,
});
const creationDate = ref(new Date());
creationDate.value = moment(props.value.creationDate).format(
  "YYYY-MM-DD HH:m:s"
);
</script>
<template>
  <div class="room-item">
    <div class="text-room">
      <h1 class="name changeContent">
        Название Комнаты:
        {{ props.value.name }}
      </h1>
      <span v-if="props.value.cityRequired">
        <i> Только для тех кто - </i>
      </span>
      <span v-else>
        <i> Желательно вы из - </i>
      </span>
      <span> из города:</span>
      <span class="changeContent"> {{ props.value.city?.name }}</span>

      <div>
        Комната создана
        <span class="changeContent">
          {{ creationDate }}
        </span>
      </div>
      <br />
      Вместимость:
      {{ props.value.capacity }} чел.
      <br />
      <span v-if="props.value.ageRequired">
        <i> Необходимо чтобы ваш возраст был: </i>
      </span>
      <span v-else> Желательно ваш возраст: от </span>
      <span class="changeContent"
        >{{ props.value.minAge }} до {{ props.value.maxAge }}</span
      >
      <br />
      Описание Комнаты:
      <span class="changeContent"> {{ props.value.description }}</span>
      <br />
    </div>
    </div>
   

</template>

<style scoped lang="less">
@import url("@/assets/styles.css");
@import url("@/assets/base.less");

@item-height: 3rem;

.text-room {
  float: left;
  width: 60%;
  color: black;
}
.name {
  padding-left: 10px;
}
.men-class {
  color: blue;
}
.avatars {
  width: 40%;
  float: right;
}
.avatar-box {
  overflow: auto;
}
.avatar-label {
  float: left;
  padding: 2em;
}
.avatar {
  transition: all 0.5s;
}
.avatar:hover {
  transform: scale(2);
}
.user-name {
  padding-bottom: 5px;
}
</style>
