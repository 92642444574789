import apiAxios from "@/services/axios/apiAxios";

const roomService = {
  getRoom: async (roomId) => {
    const response = await apiAxios.get(`/api/room/${roomId}`);
    const room = response.data;
    return room;
  },
  getRoomMessages: async (roomId) => {
    const response = await apiAxios.get(`/api/room/${roomId}/messages`);
    const messages = response.data;
    return messages;
  },
  getRooms: async (filter) => {
    const response = await apiAxios.post(`/api/room/search`, filter);
    const rooms = response.data;
    return rooms;
  },
  getRoomsAnonymous: async () => {
    const response = await apiAxios.post(`/api/room/search-anonymous`);
    const rooms = response.data;
    return rooms;
  },
  createRoom: async (room) => {
    const response = await apiAxios.post(`/api/room/`, room);
    const createdRoom = response.data;
    return createdRoom;
  },
  startSessions: async (roomId) => {
    await apiAxios.post(`/api/room/${roomId}/start-sessions`);
  },
  likeUser: async (roomId, userId) => {
    await apiAxios.post(`/api/room/${roomId}/like/${userId}`);
  },
  unlikeUser: async (roomId, userId) => {
    await apiAxios.post(`/api/room/${roomId}/unlike/${userId}`);
  },
};

export default roomService;
